<template>
  <div>
    <img
      width="240"
      height="320"
      v-if="this.metadataListFirestItem&&this.metadataListFirestItem.url"
      :src="this.metadataListFirestItem.url"
      :originalimage="this.metadataListFirestItem.url"
      class="attraction-main-image"
      @error="loadErrorImage($event, this.metadataListFirestItem.url)"
    >
    <div v-if="this.metadataListFirestItem && this.metadataListFirestItem.dateTaken">
      <H5>
        this is the date to add the atraction
      </H5>
      <H3 v-if="this.metadataListFirestItem && this.metadataListFirestItem.dateTaken">
        {{this.metadataListFirestItem.dateTaken}}
      </H3>
    </div>
    <div v-if="this.metadataListFirestItem && !this.metadataListFirestItem.dateTaken">
      <button 
        type="button"
        class="btn btn-warning"
        @click="InternallAddAttractionToRideFromImage({})" 
      >
        we dont have information about this image and we will not add it at this time - click me to continue
      </button>
    </div>
    
    <div v-if="!this.shouldPresentMoreOptionsNamesForAttraction">
      <div v-if="this.openAichosenPlace&&this.openAichosenPlace.success=='true'">
        <h1>
          is it:
        </h1>
        <h1>
          {{this.openAichosenPlace["displayName"]["text"]}} 
          ?
        </h1>
      </div>
      <h1 v-else-if="this.openAichosenPlace&&this.openAichosenPlace.success=='false'&&this.openAichosenPlace['displayName']['text']">
        {{this.openAichosenPlace['displayName']['text']}}
      </h1>
      <div v-else-if="this.openAichosenPlace&&this.openAichosenPlace.success=='false'">
        <h1>
          we did not secside to identify the place 
        </h1>
              <button 
          type="button"
          class="btn btn-warning"
          @click="presentMoreOptionsNamesForAttraction()"
        >
          show me other options
        </button>
      </div>
      <button 
        type="button"
        class="btn btn-primary"
        @click="InternallAddAttractionToRideFromImage(openAichosenPlace)" 
      >
        yes
      </button>
      <button 
        type="button"
        class="btn btn-warning"
        @click="presentMoreOptionsNamesForAttraction()"
      >
        no
      </button>
    </div>

    
    
    <div v-else >
      <h1>
          plese chuse from the list or insert the name:
      </h1>
      <gmap-autocomplete
          @place_changed="editPlaceAndAddAtraction"
          :select-first-on-enter="true"
          v-on:input="handleInputAddGooglePlaceForImage"
          class="form-control pading_top_and_butom_3 text-end py-3 fs-5 rounded-pill border-0 bg-white shadow "
          :options="{
          fields: ['place_id'],
        }"
      >
      </gmap-autocomplete>

      <div v-for="(meta, index) in this.googleLocationsOptions" :key="index">
        <button 
          type="button"
          class="btn btn-warning"
          @click="InternallAddAttractionToRideFromImage(meta)"
        >
          {{meta["displayName"]["text"]}}
        </button>
        <p/>

      </div>
  
      <button 
        type="button"
        class="btn btn-warning"
        @click="InternallAddAttractionToRideFromImage({})" 
      >
        not listed - go to the next one
      </button>
    </div>
  </div>
</template>

<script>
import 'firebase/storage';
import 'firebase/firestore';
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      shouldPresentMoreOptionsNamesForAttraction: false,
    };
  },
  computed: {
      ...mapGetters([ 'metadataList',
                      'metadataListFirestItem',
                      'openAichosenPlace',
                      "googleLocationsOptions",
                      "uploadRideId",
                    ]),
  },
  methods: {
    ...mapActions([
      "sendingImagesMetaData",
      "addAttractionToRideFromImage",
      "showModal",
      "openRideInformationPage",
      "showModalWithParameters",
      "fetchRide",
    ]),
    handleInputAddGooglePlaceForImage(event) {
      console.log("this place information is: ", event.target.value)
    },
    editPlaceAndAddAtraction(place){
      place.id = place.place_id
      this.InternallAddAttractionToRideFromImage( place )
    },

    presentMoreOptionsNamesForAttraction(){
      this.shouldPresentMoreOptionsNamesForAttraction = true;
    },
    DontPresentMoreOptionsNamesForAttraction(){
      this.shouldPresentMoreOptionsNamesForAttraction = false;
    },
    InternallAddAttractionToRideFromImage( place ){
      this.DontPresentMoreOptionsNamesForAttraction( );
      if (Object.keys(place).length > 0) {
        this.addAttractionToRideFromImage(place);
      }
      else {
        var removedElement = this.metadataList.shift();
        console.log("Place is empty, skipping ", removedElement);
      }

      if(this.metadataList.length > 0){
        this.sendingImagesMetaData()
      }
      else{
        this.openRideInformationPage( this.uploadRideId )
        this.showModalWithParameters(
                  { errorNumber : "ipload_trip_adding_text_stage",
                    type:'textConverter' 
                  } );
      }
    },

  },
  mounted() {
    this.sendingImagesMetaData();
    this.$store.commit("setLowedRideId", this.uploadRideId);
    // we need to wait for the ride to creat
    setTimeout(() => {
      this.fetchRide();
      }, 1500);
  },
};
</script>
