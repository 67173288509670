
import api from '../../api/uploadTrip';
import rideInformationApi from '../../api/rideInformation';
import { router } from '../../main';
import editRideApi from '../../api/editRide';

const state = {
  uploadRideId: null,
  metadataList: [],
  openAichosenPlace: null,
  googleLocationsOptions: [],
};

const getters = {
  metadataList: state => state.metadataList,
  metadataListFirestItem: state => state.metadataList[0],
  uploadRideId: state => state.uploadRideId,
  openAichosenPlace: state => state.openAichosenPlace ,
  googleLocationsOptions: state => state.googleLocationsOptions,
};

const actions = {
  async sendingImagesMetaData( { commit, rootState, getters, dispatch} ){
    dispatch('showModal', 'loadingIcon');
    commit( 'setOpenAichosenPlace', {'success': 'false', 'id': null, 'displayName': {'text': 'loading the image data', 'languageCode': null}} );
    commit( 'setGoogleLocationsOptions',[] );
    const metadataList = [getters.metadataList[0]];
    const { apptoken } = rootState.appauth;
    var response = null
    // this si the part that send the images to the server
    if(metadataList[0].location.latitude!=null){
      response = await api.sendImagesMetaData(  apptoken,
                                                    metadataList );
    }
    dispatch('showModal', '');
    if( response && response.data.ServerMassageCode == "success" ){
      commit( 'setOpenAichosenPlace', response.data.locationAnalitics.openAichosenPlace.imagePlace);
      commit( 'setGoogleLocationsOptions',response.data.locationAnalitics.googleNearbyatractionsdisplayName );
    }
  },

  async createTripFromImages( {commit, rootState, getters, dispatch} ){
    const metadataList = getters.metadataList;
    const dateTakenList = metadataList.map(item => item["dateTaken"]);
    // 2024:08:20 10:17:36 to -  2024-08-20 10:17:36
    const formattedDates = dateTakenList.map(dateString => {
      if (dateString) {
        // Replace 2024:08:20 10:17:36 with 2024-08-20 10:17:36
        const formattedDateString = dateString.replace(/^(\d{4}):(\d{2}):(\d{2})/, '$1-$2-$3');
        return new Date(formattedDateString);
      } else {
        // Return null if the dateTaken is missing
        return null;
      }
    }).filter(date => date !== null);
    if (formattedDates && formattedDates.length == 0) {
      console.log(" we dont have ride information from the images and we canot supporting this right new ")
      return;
    }
    const firstDate = new Date(Math.min(...formattedDates));
    const lastDate = new Date(Math.max(...formattedDates));
    const firstDateNewFormat = await dispatch('formatDateToString', firstDate)
    const lastDateNewFormat = await dispatch('formatDateToString', lastDate)
    const timeDifference = Math.abs(lastDate - firstDate);

    // Convert milliseconds to days
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
    
    if (dayDifference > 60) {
      console.log("We are not supporting trips longer than 60 days and this is: " , dayDifference , "days.");
      return;
    }    
    var rideInformation = {}
    rideInformation['title'] = "add the vication title";
    rideInformation['photos_albom_link'] = "";
    rideInformation['text'] = "add vication information";
    rideInformation['checkIn'] = firstDateNewFormat;
    rideInformation['checkOut'] = lastDateNewFormat;
    rideInformation['number_of_adult'] = 2;
    rideInformation['number_of_teen'] = 1;
    rideInformation['number_of_child'] = 1;
    rideInformation['physical_disabilities'] = "false";
    
    const { apptoken } = rootState.appauth;
    const response = await rideInformationApi.setRideInformation(apptoken,
      rideInformation);
    
    commit('setUploadRideId', response.data.rideId);
    
    if (response.statusText == "OK") {
      await dispatch('rideInformationLoadDefault'); 
      router.push('/add_attraction');
    }
    return true
  },

  async addAttractionToRideFromImage( {rootState, getters},  place  ) {
    const metadataList = getters.metadataList;
    const uploadRideId = getters.uploadRideId;
    if (metadataList && metadataList.length > 0) {
      const firstElement = metadataList.shift();
      const dateTaken = firstElement.dateTaken
      const formattedDateTaken = new Date(dateTaken.replace(/^(\d{4}):(\d{2}):(\d{2})/, '$1-$2-$3')).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
      var newAttractionInformation = {}
      newAttractionInformation['ride_id'] = uploadRideId;
      newAttractionInformation['image_url'] = firstElement.url;
      newAttractionInformation['date_taken'] = formattedDateTaken;
      newAttractionInformation['place_id'] = place.id;
      
      const { apptoken } = rootState.appauth;
      await editRideApi.addAttractionFromImageToRide(apptoken,
        newAttractionInformation);
    }
    return true
  },
  async createAccommodationToRideFromImage(){
    return true
  },

  formatDateToString( _ , date) {
    const day = String(date.getDate()).padStart(2, '0');  // Ensures two digits for day
    const month = String(date.getMonth() + 1).padStart(2, '0');  // Months are zero-indexed, so we add 1
    const year = date.getFullYear();  // Get the full year    
    return `${day}-${month}-${year}`;
  },

};

const mutations = {
  setMetadataList: ( state, metadataList) => {
    state.metadataList = metadataList;
  },
  setUploadRideId:(state, uploadRideId) => {
    state.uploadRideId = uploadRideId;
  },
  setOpenAichosenPlace:(state, openAichosenPlace) => {
    state.openAichosenPlace = openAichosenPlace;
  },
  setGoogleLocationsOptions:(state, googleLocationsOptions) => {
    state.googleLocationsOptions = googleLocationsOptions;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
