let config;

// if (process.env.NODE_ENV === "production") {
//   config = {
//     $api_url: "https://api.xxx.com",
//     timeoutDuration: 30000,
//     someOtherProps: 'xyz'
//   };
// } else {
//   config = {
//     $api_url: "https://yyy.test:8443",
//     timeoutDuration: 1000,
//     someOtherProps: 'abc'
//   };
// }
config = {
        ROOT_URL: process.env.VUE_APP_ROOT_URL,
        
        //ROOT_URL: 'https://tripplanneril.herokuapp.com',
        //ROOT_URL: 'http://127.0.0.1:5000',
        // ROOT_URL: 'https://95.111.252.227',
        // $api_url: "https://api.xxx.com",
        // timeoutDuration: 30000,
        // someOtherProps: 'xyz'
        GOOGLE_API_KEY: "AIzaSyAvS-PfvIH2AHOYi4EOOaDkqm2s-2nwVbg",
        // GOOGLE_ANALITICS_KEY: 'G-26CZN44FVG',
                               
        //GOOGLE_ANALITICS_KEY: 'G-MQ30LL5DVF',
        // GOOGLE_ANALITICS_KEY: 'false',
        BOOKING_B_ATTRACTION_ICON: "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fattraction%2Fb-solid%202.png?alt=media&token=808e05d4-427b-4425-aceb-da08acd090be",
        GET_YOUR_GUIDE_ATTRACTION_ICON: "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fattraction%2Fget_your_guide.png?alt=media&token=6a5292a6-cdf4-4cea-bcae-0cb3711da4a0",
        LINK_ATTRACTION_ICON: "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fattraction%2Flink-solid_icon.png?alt=media&token=60799668-e700-4048-bd2d-d6ba64e309f1",
        SHARE_ATTRACTION_IMAGE: "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fattraction%2Fsher_icon.png?alt=media&token=799ef08f-bb0d-4ae7-9c3b-e8886d428092",
        WAZE_ATTRACTION_ICON: "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fattraction%2Fwaze_icon.png?alt=media&token=9b21c538-4286-4d64-b700-a3c1ae2993ed",
        FIRE_BASE_API_KEY: "AIzaSyDVy2--48v4knREZpNqd7PAsd9TVqppbwE",
        GOOGLE_FORM_ADD_NEW_RIDE_WITH_ALBUM: 'https://docs.google.com/forms/d/e/1FAIpQLSdU7uVJES71fVCA0gId9FChlJm7HOZAevJpQHZRrLxNT3lV8g/viewform?usp=sf_link',
        BLACK_MAP_POINT_WITH_NUMBERS_AND_COLLOR: [
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_1_.png?alt=media&token=b4d321fb-259a-4374-b571-c205eb88bfa6',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_2.png?alt=media&token=2431d00b-fdf3-439b-80b2-06561d7d2912',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_3.png?alt=media&token=5f0e5023-5f8e-4885-832e-b15a6f6227b3',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_4.png?alt=media&token=eeb1a77d-6355-4c32-a690-eb96732b2519',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_5.png?alt=media&token=77b96872-4c2d-48df-b21e-184c3b34199c',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_6.png?alt=media&token=6af16251-8fc1-4e9f-94d8-eac8d358b9c7',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_7.png?alt=media&token=0827192c-f6a4-48ce-bf2d-53edbfb464c4',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_8.png?alt=media&token=6806a272-4a4d-451d-9fa0-a2b2482cde74',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_9.png?alt=media&token=4dca5a23-cf7c-47c9-8f36-f8f9136b998d',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_plus.png?alt=media&token=cb4ae851-a42c-4de2-a9ef-b2ab14b9668b',
             COLLER: '#000000'},
        ],
        BLUE_MAP_POINT_WITH_NUMBERS: [
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_1.png?alt=media&token=7165e8cb-b067-42c8-85db-beb12db67de6',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_2.png?alt=media&token=06e25128-b6cf-44db-83d6-bc0d67e4a349',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_3.png?alt=media&token=2c5339d0-3879-4e17-9496-df2d7a3d23d0',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_4.png?alt=media&token=3dbce3c1-48a0-4024-be98-aa85285d3bbd',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_5.png?alt=media&token=95c4aa88-3c72-4f20-816d-1af655182401',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_6.png?alt=media&token=b0295a7d-ffd1-44f6-8d6a-2cfea0f44ee9',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_7.png?alt=media&token=a2904db5-7f68-4618-b85a-f937606361fc',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_8.png?alt=media&token=c7619017-b9bc-49ad-a7db-fbf0698445f1',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_9.png?alt=media&token=239c32be-b355-4984-825f-9a33780febb4',
             COLLER: '#000000'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_%2B.png?alt=media&token=39fe571d-7315-4c5c-80df-57a5a7dc57fd',
             COLLER: '#000000'},
        ],
        BLACK_MAP_POINT_WITH_NUMERATOR_FULL: [
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_1_.png?alt=media&token=b4d321fb-259a-4374-b571-c205eb88bfa6'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_2.png?alt=media&token=2431d00b-fdf3-439b-80b2-06561d7d2912'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_3.png?alt=media&token=5f0e5023-5f8e-4885-832e-b15a6f6227b3'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_4.png?alt=media&token=eeb1a77d-6355-4c32-a690-eb96732b2519'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_5.png?alt=media&token=77b96872-4c2d-48df-b21e-184c3b34199c'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_6.png?alt=media&token=6af16251-8fc1-4e9f-94d8-eac8d358b9c7'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_7.png?alt=media&token=0827192c-f6a4-48ce-bf2d-53edbfb464c4'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_8.png?alt=media&token=6806a272-4a4d-451d-9fa0-a2b2482cde74'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_9.png?alt=media&token=4dca5a23-cf7c-47c9-8f36-f8f9136b998d'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_plus.png?alt=media&token=cb4ae851-a42c-4de2-a9ef-b2ab14b9668b'},
        ],
        BLUE_MAP_POINT_WITH_NUMERATOR_AND_LINE: [
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_1-sleeping-place.png?alt=media&token=d81cfd02-ea80-494b-8d11-0fe34d885c5e'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_2-sleeping-place.png?alt=media&token=68c7d681-675d-405c-8d18-05a8d37c20d6'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_3-sleeping-place.png?alt=media&token=c978465e-ff45-4de2-98a0-4778dec8ffae'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_4-sleeping-place.png?alt=media&token=6c75960f-2482-445c-afc9-cc9c4eaf7f25'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_5-sleeping-place.png?alt=media&token=118e983b-cced-4e69-b58f-d17e529d5def'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_6-sleeping-place.png?alt=media&token=7a5ed388-1073-49fb-9cf9-584bdd26eafc'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_7-sleeping-place.png?alt=media&token=895f5022-c892-4dc4-8fdf-fd1d3370bda5'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_8-sleeping-place.png?alt=media&token=23363046-e45b-433d-9636-5507fd2cb62c'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_9-sleeping-place.png?alt=media&token=84a206e8-6e9e-4bf1-b648-a909341bb2c3'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_10%2B-sleeping-place.png?alt=media&token=c909d64b-e109-43c7-b032-aafc43eebe46'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_11%2B-sleeping-place.png?alt=media&token=64c080c2-02d5-469a-a2c1-109d562b10ba'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_10%2B-sleeping-place.png?alt=media&token=c909d64b-e109-43c7-b032-aafc43eebe46'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_11%2B-sleeping-place.png?alt=media&token=64c080c2-02d5-469a-a2c1-109d562b10ba'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_10%2B-sleeping-place.png?alt=media&token=c909d64b-e109-43c7-b032-aafc43eebe46'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_11%2B-sleeping-place.png?alt=media&token=64c080c2-02d5-469a-a2c1-109d562b10ba'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_10%2B-sleeping-place.png?alt=media&token=c909d64b-e109-43c7-b032-aafc43eebe46'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_11%2B-sleeping-place.png?alt=media&token=64c080c2-02d5-469a-a2c1-109d562b10ba'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_10%2B-sleeping-place.png?alt=media&token=c909d64b-e109-43c7-b032-aafc43eebe46'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Flines%2Fhotels_line_11%2B-sleeping-place.png?alt=media&token=64c080c2-02d5-469a-a2c1-109d562b10ba'},
        ],
        BLUE_MAP_POINT_WITH_NUMERATOR: [
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_1.png?alt=media&token=7165e8cb-b067-42c8-85db-beb12db67de6'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_2.png?alt=media&token=06e25128-b6cf-44db-83d6-bc0d67e4a349'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_3.png?alt=media&token=2c5339d0-3879-4e17-9496-df2d7a3d23d0'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_4.png?alt=media&token=3dbce3c1-48a0-4024-be98-aa85285d3bbd'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_5.png?alt=media&token=95c4aa88-3c72-4f20-816d-1af655182401'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_6.png?alt=media&token=b0295a7d-ffd1-44f6-8d6a-2cfea0f44ee9'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_7.png?alt=media&token=a2904db5-7f68-4618-b85a-f937606361fc'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_8.png?alt=media&token=c7619017-b9bc-49ad-a7db-fbf0698445f1'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_9.png?alt=media&token=239c32be-b355-4984-825f-9a33780febb4'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fmap_icon_%2B.png?alt=media&token=39fe571d-7315-4c5c-80df-57a5a7dc57fd'},
        ],
        MAP_POINT_WITH_NUMERATOR: [
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIcon1.png?alt=media&token=bb7b0e53-9b0a-4fd5-b6b7-15fc236d8f04',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColor2.png?alt=media&token=dd5dd341-5c68-452d-840c-7fdf762749f5',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColor3.png?alt=media&token=c33ed876-147a-44ef-8c72-f956ddf83f90',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColor4.png?alt=media&token=2c240100-78f9-4e8e-af43-976327152b6c',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColor5.png?alt=media&token=4f20e0b0-b3dd-4654-9a90-dc2f859f1091',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColor6.png?alt=media&token=04d20d57-b513-4f7e-a842-7c1beaecb09d',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColor7.png?alt=media&token=fe8b9f8c-8178-4198-83d0-d980a626569f',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColor8.png?alt=media&token=dc8708a8-7547-486a-85c1-acb3b48ae15b',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColor9.png?alt=media&token=8208f5db-6e15-4500-88cb-bba11e1971ef',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIcon%2B.png?alt=media&token=950669e5-9203-4170-be28-4107a3bc55ca',
      
        ],
        MAP_POINT_WITH_NUMERATOR_FULL: [
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2FmapIconWithColor_A_.png?alt=media&token=f39c2912-1b2d-41a8-b21d-d5cc08691fd7',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColorFull1.png?alt=media&token=f3046fe4-2e01-418a-833d-fd7e8a2f4a31',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColorFull2.png?alt=media&token=45667eb4-eba0-4e40-9f71-b4012b0f2580',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColorFull3.png?alt=media&token=52dc2747-5e9b-4c36-921f-3943800ed793',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColorFull4.png?alt=media&token=31f2d8b5-1eba-4caa-9324-cecd9cfe0795',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColorFull5.png?alt=media&token=a38df386-9264-4315-b481-ee9648d8b449',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColorFull6.png?alt=media&token=34307df7-dcdb-4295-b96f-663c8ea8119d',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColorFull7.png?alt=media&token=b9c54700-c41c-4f0b-ae58-a837a4c26159',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColorFull8.png?alt=media&token=e653a5c9-256f-4754-ada1-a8b2013a7572',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColorFull9.png?alt=media&token=f5d3a559-89b7-4344-9ab5-5a4c63e2b2dc',
            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FmapIconWithColorFull%2B.png?alt=media&token=d2b7264f-dcb1-49ee-bd29-52e48b5f1fb7',      
        ],
        RED_MAP_POINT_WITH_NUMERATOR_FULL: [
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fred_numbers%2Fred_map_Icon_1_.png?alt=media&token=3f592ad6-ac24-4c61-999f-39ba64a75884'},
        ],
        MAP_POINT_WITH_LETTER_FULL: [
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2FmapIconWithColor_A_.png?alt=media&token=f39c2912-1b2d-41a8-b21d-d5cc08691fd7'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2FmapIconWithColor_B_.png?alt=media&token=55bbb397-1520-475b-a3eb-46af3adc4ebc'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2FmapIconWithColor_C_.png?alt=media&token=42a59cf7-a12d-4ad8-9a48-0a41325b4e59'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2FmapIconWithColor_D_.png?alt=media&token=e28e8dfd-ec2e-40ae-8dc4-43db45650ef7'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2FmapIconWithColor_E_.png?alt=media&token=7455b4d2-ce2f-4e5f-aacb-46b480913ebb'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2FmapIconWithColor_F_.png?alt=media&token=0ca3543f-5b93-4c3d-8c34-f10eb1c3b899'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2FmapIconWithColor_G_.png?alt=media&token=985242de-a4d4-4889-add1-f724e2614412'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2FmapIconWithColor_H_.png?alt=media&token=625d1924-1903-4307-a31f-33f014f11952'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2FmapIconWithColor_I_.png?alt=media&token=cca5fa96-c881-4491-b870-6f1920e7a54f'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2FmapIconWithColor_J_.png?alt=media&token=878ab928-40c5-4e94-ae0b-76a0c672c9c7'},
            
        ],
        MAP_POINT_WITH_COLLOR: [
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FfullColers%2FpointWithFullColler_07A0C3.png?alt=media&token=c3e342d6-f425-495f-842d-c0e6edd2730e',
             COLLER: '#07A0C3'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FfullColers%2FpointWithFullColler_3A1772.png?alt=media&token=9c3b8cd3-bb2e-4dde-83be-9538bd2df00a',
             COLLER: '#3A1772'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FfullColers%2FpointWithFullColler_3F7D20.png?alt=media&token=124cf21d-d15b-4d01-9fa7-7b2d60bf64d8',
             COLLER: '#3F7D20'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FfullColers%2FpointWithFullColler_616163.png?alt=media&token=9b5fbf03-852d-4a35-9864-2a9ff2672551',
             COLLER: '#616163'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FfullColers%2FpointWithFullColler_78D05B.png?alt=media&token=3fb8240c-d3e0-4e31-a60b-8f3c01b9ef9a',
             COLLER: '#78D05B'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FfullColers%2FpointWithFullColler_8691B9.png?alt=media&token=9a824ebb-2777-407f-a557-f8d8cae12931',
             COLLER: '#8691B9'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FfullColers%2FpointWithFullColler_BB6BD9.png?alt=media&token=eae420b3-d28a-4f78-8266-9de629e5aa76',
             COLLER: '#BB6BD9'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FfullColers%2FpointWithFullColler_C1B740.png?alt=media&token=559bc204-b0f4-4558-90dd-14acfefe7bb6',
             COLLER: '#C1B740'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FfullColers%2FpointWithFullColler_C83E4D.png?alt=media&token=740162ff-9d83-4cc8-a697-4a201b5a23c0',
             COLLER: '#C83E4D'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FfullColers%2FpointWithFullColler_E0BE36.png?alt=media&token=8b8b60da-9013-4f56-9f28-7049fd071505',
             COLLER: '#E0BE36'},
            {IMAGE_URL:'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FfullColers%2FpointWithFullColler_FF7B9C.png?alt=media&token=910ea760-b3cd-40c2-9994-309fd43846d7',
             COLLER: '#FF7B9C'},            
        ],
        MAP_POINT_ACCOMMODATION:            'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fhotel_icon.png?alt=media&token=995f10b6-1a8f-4450-9a58-7e1eb828dbaa',
        MAP_POINT_ACCOMMODATION_CHECKIN:    'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fcheckin_icon.png?alt=media&token=5a44ab49-3764-46df-a181-660c84799df2',
        MAP_POINT_ACCOMMODATION_CHECKOUT:   'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Fcheckout_icon.png?alt=media&token=4316ca9f-d9dc-4e23-8dcc-4c7ecf1d7acb',
        VERY_GOOD_TRIPS: [1,183,230,105,235,224,218, 304],
        RIDE_TO_BOKING_ID_CONVERTOR:
        {   
            232:{"data-aid":"2283612","data-target_aid":"2283612","data-dest_id":"-1995499","data-dest_type":"city"},
            1:  {"data-aid":"2284180","data-target_aid":"2284180","data-dest_id":"-1977078","data-dest_type":"city","name":"austria-flachau"},
            72: {"data-aid":"2284180","data-target_aid":"2284180","data-dest_id":"-1977078","data-dest_type":"city","name":"austria-flachau"},
            66: {"data-aid":"2284180","data-target_aid":"2284180","data-dest_id":"-1977078","data-dest_type":"city","name":"austria-flachau"},
            55: {"data-aid":"2284180","data-target_aid":"2284180","data-dest_id":"-1977078","data-dest_type":"city","name":"austria-flachau"},
            68: {"data-aid":"2284180","data-target_aid":"2284180","data-dest_id":"-1977078","data-dest_type":"city","name":"austria-flachau"},
            79: {"data-aid":"2284180","data-target_aid":"2284180","data-dest_id":"-1977078","data-dest_type":"city","name":"austria-flachau"},
            107: {"data-aid":"2284229","data-target_aid":"2284229","data-dest_id":"-2601889","data-dest_type":"city","name":"london"},
            36 : {"data-aid":"2284230","data-target_aid":"2284230","data-dest_id":"-87612","data-dest_type":"city"},
            221: {"data-aid":"2284231","data-target_aid":"2284231","data-dest_id":"-1829149","data-dest_type":"city"},
            214: {"data-aid":"2284239","data-target_aid":"2284239","data-dest_id":"-1806467","data-dest_type":"city","name":"germany-kirchzarten"},
            231: {"data-aid":"2284240","data-target_aid":"2284240","data-dest_id":"-850553","data-dest_type":"city","name":"budapest"},
            225: {"data-aid":"2284240","data-target_aid":"2284240","data-dest_id":"-850553","data-dest_type":"city","name":"budapest"},
            233: {"data-aid":"2284241","data-target_aid":"2284241","data-dest_id":"-1761619","data-dest_type":"city","name":"germany-darsden"},
            220: {"data-aid":"2284242","data-target_aid":"2284242","data-dest_id":"-2154382","data-dest_type":"city","name":"holand-utrecht"},
            183: {"data-aid":"2284243","data-target_aid":"2284243","data-dest_id":"-538221","data-dest_type":"city","name":"poland-zakopane"},
            141: {"data-aid":"2284243","data-target_aid":"2284243","data-dest_id":"-538221","data-dest_type":"city","name":"poland-zakopane"},
            230: {"data-aid":"2284245","data-target_aid":"2284245","data-dest_id":"-1810561","data-dest_type":"city","name":"germany-koln"},
            229: {"data-aid":"2284246","data-target_aid":"2284246","data-dest_id":"-372490","data-dest_type":"city","name":"barcelona"},
            105: {"data-aid":"2284247","data-target_aid":"2284247","data-dest_id":"-400284","data-dest_type":"city","name":"spain-salou"},
            235: {"data-aid":"2284248","data-target_aid":"2284248","data-dest_id":"-1456928","data-dest_type":"city","name":"paris"},
            219: {"data-aid":"2284249","data-target_aid":"2284249","data-dest_id":"-2140479","data-dest_type":"city","name":"amsterdam"},
            224: {"data-aid":"2284249","data-target_aid":"2284249","data-dest_id":"-2140479","data-dest_type":"city","name":"amsterdam"},
            218: {"data-aid":"2284250","data-target_aid":"2284250","data-dest_id":"-1153613","data-dest_type":"city","name":"brasov romanis"},
            223: {"data-aid":"2284251","data-target_aid":"2284251","data-dest_id":"-2745636","data-dest_type":"city","name":"denemark kopenhaven"},
            228: {"data-aid":"2284252","data-target_aid":"2284252","data-dest_id":"-2595386","data-dest_type":"city","name":"england edinburgh*******"},
            212: {"data-aid":"2284253","data-target_aid":"2284253","data-dest_id":"900047975","data-dest_type":"city","name":"georgia tbilisi"},
            217: {"data-aid":"2284254","data-target_aid":"2284254","data-dest_id":"-3414440","data-dest_type":"city","name":"bankok"},
            222: {"data-aid":"2284254","data-target_aid":"2284254","data-dest_id":"-3414440","data-dest_type":"city","name":"bankok"},
            237: {"data-aid":"2284251","data-target_aid":"2284251","data-dest_id":"-2745636","data-dest_type":"city","name":"denemark kopenhaven"},
            238: {"data-aid":"2284254","data-target_aid":"2284254","data-dest_id":"-3414440","data-dest_type":"city","name":"bankok"},
            239: {"data-aid":"2284723","data-target_aid":"2284723","data-dest_id":"-516512","data-dest_type":"city","name":"poland-Mikolajki"},
            240: {"data-aid":"2284721","data-target_aid":"2284721","data-dest_id":"-2326360","data-dest_type":"city","name":"georgia-borjomi"},
            241: {"data-aid":"2284180","data-target_aid":"2284180","data-dest_id":"-1977078","data-dest_type":"city","name":"austria-flachau"},
            250: {"data-aid":"2309207","data-target_aid":"2309207","data-dest_id":"1427","data-dest_type":"region","name":"como_italy"},
            304: {"data-aid":"2297942","data-target_aid":"2297942","data-dest_id":"3630","data-dest_type":"region","name":"Yosemite_National_Park_United_States"},
            308: {"data-aid":"2299180","data-target_aid":"2299180","data-dest_id":"20030916","data-dest_type":"city","name":"Honolulu_Hawaii_United_States"},
            309: {"data-aid":"2301624","data-target_aid":"2301624","data-dest_id":"-782831","data-dest_type":"city","name":"Dubai"},
            310: {"data-aid":"2284246","data-target_aid":"2284246","data-dest_id":"-372490","data-dest_type":"city","name":"barcelona"},
            375: {"data-aid":"2284248","data-target_aid":"2284248","data-dest_id":"-1456928","data-dest_type":"city","name":"paris"},
            509: {"data-aid":"2427431","data-target_aid":"2427431","data-dest_id":"-829252","data-dest_type":"city","name":"greece-Thessaloniki"},
            719: {"data-aid":"2284246","data-target_aid":"2284246","data-dest_id":"-372490","data-dest_type":"city","name":"barcelona"},
            748: {"data-aid":"2420138","data-target_aid":"2420138","data-dest_id":"-1746443","data-dest_type":"city","name":"Berlin"},
            791: {"data-aid":"2420129","data-target_aid":"2420129","data-dest_id":"20088325","data-dest_type":"city","name":"new-york"},
            794: {"data-aid":"2420138","data-target_aid":"2420138","data-dest_id":"-1746443","data-dest_type":"city","name":"Berlin"},
            838: {"data-aid":"2284246","data-target_aid":"2284246","data-dest_id":"-372490","data-dest_type":"city","name":"barcelona"},
            842: {"data-aid":"2284246","data-target_aid":"2284246","data-dest_id":"-372490","data-dest_type":"city","name":"barcelona"},
            843: {"data-aid":"2420129","data-target_aid":"2420129","data-dest_id":"20088325","data-dest_type":"city","name":"new-york"},
            924: {"data-aid":"2427432","data-target_aid":"2427432","data-dest_id":"2813","data-dest_type":"region","name":"Mykonos-Greece"},
            940: {"data-aid":"2427433","data-target_aid":"2427433","data-dest_id":"2458","data-dest_type":"region","name":"bled-slovenia"},
            945: {"data-aid":"2427434","data-target_aid":"2427434","data-dest_id":"1239","data-dest_type":"region","name":"deals-Lake_Lucerne-Switzerland"},
            946: {"data-aid":"2427435","data-target_aid":"2427435","data-dest_id":"-510625","data-dest_type":"city","name":"Krakow-Poland"},
            1046: {"data-aid":"2427424","data-target_aid":"2427424","data-dest_id":"-1153951","data-dest_type":"city","name":"Bukarest-Rumanien"},

        },
        PLACE_ID_TO_GET_YOUR_GUIDE_LINK_CONVERTOR:
        {   
            "ChIJV02ULeCnbUcRkHFsheJ2pjs":{"link":"https://www.getyourguide.com/vienna-l7/schonbrunn-zoo-tickets-vienna-t7525/?partner_id=5BL6W0P&utm_medium=online_publisher","info":"schonbrunn-zoo-tickets-vienna"},
            "ChIJv3zAHAmobUcRyYvq5F26aU4":{"link":"https://www.getyourguide.com/vienna-l7/vienna-schonbrunn-palace-gardens-skip-the-line-tour-t397843/?partner_id=5BL6W0P&utm_medium=online_publisher","info":"vienna-schonbrunn-palace-gardens"},
            "ChIJoRP9487udkcR2FyjnqHt_wI":{"link":"https://www.getyourguide.com/salzburg-l4/eagle-s-nest-berchtesgaden-tour-t9049/?partner_id=5BL6W0P&utm_medium=online_publisher","info":"eagle-s-nest-berchtesgaden-tour"},
            "ChIJzeu33ldFmUcRiH3Q9qb5HMk":{"link":"https://www.getyourguide.com/legoland-germany-l35307/legoland-deutschland-resort-skip-the-line-ticket-t286270/?partner_id=5BL6W0P&utm_medium=online_publisher","info":""},
            "ChIJVVf9x50HbUcRPYtBoZmur0g":{"link":"https://www.getyourguide.com/vienna-l7/visit-at-the-sound-museum-haus-der-musik--t707/?partner_id=5BL6W0P&utm_medium=online_publisher","info":"visit-at-the-sound-museum-haus-der-musik"},
            "ChIJD3uTd9hx5kcR1IQvGfr8dbk":{"link":"https://www.getyourguide.com/paris-l16/louvre-museum-skip-the-line-entrance-ticket-t145779/?partner_id=5BL6W0P&utm_medium=online_publisher","info":"paris-louvre-museum"},
            "ChIJveJ6yhkd5kcRYOYQY8v4-lc":{"link":"https://www.getyourguide.com/paris-l16/disneyland-paris-2-parks-ticket-1-2-3-4-5-day-t395320/?partner_id=5BL6W0P&utm_medium=online_publisher","info":"disneyland-paris"},
            "ChIJT1Hoyxgd5kcRJqSfRoM3SAo":{"link":"https://www.getyourguide.com/paris-l16/disneyland-paris-2-parks-ticket-1-2-3-4-5-day-t395320/?partner_id=5BL6W0P&utm_medium=online_publisher","info":"disneyland-paris"},
            "ChIJG5Qwtitu5kcR2CNEsYy9cdA":{"link":"https://www.getyourguide.com/musee-d-orsay-l2976/paris-musee-d-orsay-entry-ticket-and-seine-river-cruise-t405100/?partner_id=5BL6W0P&utm_medium=online_publisher","info":"paris-musee-d-orsay"},
            "ChIJGW2UsWqTFkcRCUTc99B1_Gk":{"link":"https://www.getyourguide.com/energylandia-l91857/krakow-energylandia-amusement-park-entrance-ticket-t265865/?partner_id=5BL6W0P&utm_medium=online_publisher","info":"krakow-energylandia"},
            "ChIJvZqg4v1bFkcRbEH9q5nupvM":{"link":"https://www.getyourguide.com/krakow-l40/krakow-zoo-with-hotel-pickup-t265102/?partner_id=5BL6W0P&utm_medium=online_publisher","info":"krakow-zoo-with-hotel-pickup"},

            "ChIJceChSveYagwR8D3aVXc5w_I":{"link":"https://www.getyourguide.com/tenerife-l350/karting-club-tenerife-t74392/?partner_id=4RPWRXR&utm_medium=online_publisher&cmp=avshalom","info":"AmitIdgarID-Tenerif-Carting"},
            "ChIJoae88cZ_agwRaLaot2Y_eC0":{"link":"https://www.getyourguide.com/tenerife-l350/loro-parque-entrance-ticket-t407432/?partner_id=4RPWRXR&utm_medium=online_publisher&cmp=avshalom","info":"AmitIdgarID-Tenerif-zoo"},
            "ChIJbQKLqmGXagwR8mjuzA0wqVA":{"link":"https://www.getyourguide.com/tenerife-l350/siam-park-admission-ticket-t407436/?partner_id=4RPWRXR&utm_medium=online_publisher&cmp=avshalom","info":"AmitIdgarID-Tenerif-siam"},
            "ChIJK41kPHyRagwRMpjVLmnBmWc":{"link":"https://www.getyourguide.com/tenerife-l350/tenerife-great-chinese-lantern-world-sculpture-park-ticket-t416245/?partner_id=4RPWRXR&utm_medium=online_publisher&cmp=avshalom","info":"AmitIdgarID-Tenerif-chinese-sculpture-park"},

            // "":{"link":"","info":""},
            // "":{"link":"","info":""},
        },
        USER_PAGE_CONVERTER:
        {   
            "1":{"link":"https://account.tripplanner.co.il/","email":"avshalom@gmail.com"},
            "5":{"link":"https://account.tripplanner.co.il/","email":"avshalomkatz@gmail.com"},
            "77":{"link":"https://account.tripplanner.co.il/","email":"nofagam@gmail.com"},
            // "":{"link":"","info":""},
        },
        ROUTES_URL_NAMES:
        {
            "searchAccommodationsNyName":"search accommodations by name",
            "searchRideByName"          :"search ride by name",
        },

    };
export default { config }
