import axios from 'axios';
import config from '../common/config';

export default {

  sendImagesMetaData( token, metadataList )
    {
      const formData = new FormData()
      formData.append('data', JSON.stringify(metadataList));
      return axios.post(`${config.config.ROOT_URL}/create_trip_from_images_api`,formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data'        }
      });
    },
};