<template>
  <div>
    <input type="file" multiple @change="onFileChange">
    <div v-for="(meta, index) in this.metadataList" :key="index">
      <h3>Metadata for {{ meta.filename }}</h3>
      <p><strong>Size:</strong> {{ meta.size }} bytes</p>
      <p><strong>Type:</strong> {{ meta.type }}</p>
      <p><strong>Date Taken:</strong> {{ meta.dateTaken }}</p>
      <p><strong>Location:</strong> Latitude: {{ meta.location.latitude }}, Longitude: {{ meta.location.longitude }}</p>
      <p><strong>Uploaded At:</strong> {{ meta.uploadedAt }}</p>
      <p><strong>Download URL:</strong> {{ meta.url }}</p>
    </div>
  </div>
</template>

<script>
import EXIF from 'exif-js';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      files: [],
    };
  },
  computed: {
      ...mapGetters([ 'metadataList',
                    ]),
  },
  methods: {
    ...mapActions([
      "sendingImagesMetaData",
      "createTripFromImages",
      "createAttraction",
      "createAccommodation",
      "showModal",
    ]),

    onFileChange(event) {
      // Check if the event has the files
      
      if (event.target.files.length > 0) {
        this.files = event.target.files;  // Store selected files

        // Now you can call the uploadFiles method
        this.uploadFiles();
      } else {
        console.log("No files selected");
      }
    },
    extractExifData(file) {
      return new Promise((resolve, reject) => {
        EXIF.getData(file, function() {
          const exifData = EXIF.getAllTags(this);
          if (Object.keys(exifData).length === 0) {
            reject('No EXIF data found');
          } else {
            resolve({
              dateTaken: exifData.DateTimeOriginal,
              gpsLatitude: exifData.GPSLatitude,
              gpsLongitude: exifData.GPSLongitude,
            });
          }
        });
      });
    },
      async uploadFiles() {
        this.showModal("loadingIcon");
        const promises = [];

        for (let i = 0; i < this.files.length; i++) {
          const file = this.files[i];
          const storageRef = firebase.storage().ref(`attractionsTest/${file.name}`);
          const uploadTask = storageRef.put(file);

          promises.push(
            uploadTask
              .then(async () => {
                // Ensure that the EXIF extraction doesn't block the upload
                let exifData = {};
                try {
                  exifData = await this.extractExifData(file);
                } catch (error) {
                  console.error("Error extracting EXIF data:", error);
                }

                // Continue with the upload even if EXIF data is incomplete or missing
                const downloadURL = await storageRef.getDownloadURL();
                const metadata = {
                  filename: file.name,
                  size: file.size,
                  type: file.type,
                  dateTaken: exifData.dateTaken || null, // If EXIF data fails, use null
                  location: exifData.gpsLatitude && exifData.gpsLongitude
                    ? {
                        latitude: exifData.gpsLatitude ,
                        longitude: exifData.gpsLongitude ,
                      }
                    : { latitude: null, longitude: null }, // Default to null if no GPS data
                  uploadedAt: new Date().toLocaleString(),
                  url: downloadURL,
                };

                // Push the metadata to metadataList
                this.metadataList.push(metadata);
              })
              .catch((error) => {
                // Handle upload error but ensure other images keep uploading
                console.error("Error uploading file:", error);
              })
          );
        }

        // Ensure that all promises (uploads) are handled properly, even if some fail
        Promise.all(promises)
          .then(() => {
            this.showModal("");
            this.createTripFromImages(); // Call after all uploads are complete
          })
          .catch((error) => {
            this.showModal("");
            console.error("There was an error uploading the files!", error);
          });
      },


    updateMetadataList ( metadataList ) {
      this.$store.commit('setmetadataList', metadataList)
    },

  },
};
</script>
